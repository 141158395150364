<template>
  <div>
      <!-- Breadcrumb start -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item">Users</li>
        </ol>
        <!-- Breadcrumb end -->
  
        <!-- App actions start -->
        <b-form-group>
          <b-button
            to="users/new"
            class="btn btn-success float-left">
            <b-icon icon="plus-circle" />
            Add User
          </b-button>
          <b-form-radio-group
              id="btn-radios-users"
              v-model="selected"
             :options="options"
              name="status"
              buttons
              button-variant="primary"
              @change="getStatus(selected)"
            >
          </b-form-radio-group>
          <button v-if="selected == 'all'" class="button-legend"><span class="legend"></span><b class="legend">Deleted Users</b></button>
        </b-form-group>
    <BMSTable
      :items="items"
      :fields="fields"
      :sort-by="sortBy"
      :urlBuilder="buildUrl"
    >
      <template #cell(actions)="row">
        <b-button v-if="!row.item.deleted" @click="onDelete(row.item.id)" variant="primary">
          <b-icon icon="trash" />
        </b-button>
        <b-button v-if="row.item.deleted" @click="onActivate(row.item.id)" variant="primary">
          <b-icon icon="check-circle" />
        </b-button>
      </template>
    </BMSTable>
  </div>
  </template>
  
<script>

import { getUsers, getAllUsers, deleteUser, activateUser} from '@/api/users.api'
import BMSTable from '@/components/BMSTable';
import confirmationModal from '@/api/confirmation'
import crudMixin from '@/api/crud'

export default {
  mixins: [crudMixin,confirmationModal],
  data() {
    return {
      fields: [{
        key: 'username',
        label: 'Username',
        sortable: true,
      }, {
        key: 'email',
        label: 'Email',
        sortable: true,
      }, {
        key: 'phone',
        label: 'Phone number',
        sortable: true,
      }, {
        key: 'role',
        label: 'Role',
        sortable: true,
      }, {
        key: 'firstname',
        label: 'First name',
        sortable: true,
      }, {
        key: 'lastname',
        label: 'Last name',
        sortable: true,
      }, {
        label: 'Action',
        key: 'actions'
      }],
      items: null,
      filter: null,
      sortBy: 'username',
      options : [
        {text : 'Active', value : 'active'},
        {text : 'All', value : 'all'}
      ],
      selected:'active',
    }
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getStatus(selected){
      if (selected == 'all'){
        this.call(
        getAllUsers(),
        (res) => {
          this.items = res.data.users
        }
      )
      }else{
        this.call(
        getUsers(),
        (res) => {
          this.items = res.data.users
        }
      )
      }
    },
    onDelete: function (id) {
      // confirmModal(message, okTitle, cancelTitle)
      this.confirmModal().then((result) => {
        if (result){
        this.deleteObject(
          deleteUser(id),
          () =>{
            this.$router.go(0)
          }
        )
      }
      })
    },
    onActivate: function(id){
      // confirmModal(message, okTitle, cancelTitle, okVariant {default: danger})
      this.confirmModal("Are you sure?", "ACTIVATE", "CANCEL", "success").then((result) => {
        if (result){
        this.saveObject(
          activateUser(id),
          () =>{
            this.$router.go()
          }
        )
      }
      })
   },
    buildUrl(user){
      return { name: 'User', params: { id: user.id }}
    }
  },
  components: {
    BMSTable
  }
}

</script>
<style>
span.legend {
border: 1px solid #ccc; 
float: left; 
width: 20px; 
height: 20px; 
margin: 4px;
background-color: #a5a5a5;
}
button.button-legend{
  border: none;
  pointer-events: none;
}
.btn-primary{
  background-color: white;
  color: #4F2B76;
}
.btn .btn-primary .active{
  color: #fff;
  background-color: #4F2B76;
  border-color: #4F2B76;
}

</style>

